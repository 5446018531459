import React from 'react';

import styles from '../styles/Global';
import { scene } from '../assets';

const Download = () => {
  return (
    <div className={`${styles.section} ${styles.bgWhite}`}>
      <div className={`${styles.subSection} flex-col text-center`}>
        <div>
          <h1 className={`${styles.h1Text} ${styles.blackText}`}>Get the app</h1>
          <p className={`${styles.pText} ${styles.blackText}`}>Start managing your business efficiently</p>
        </div>
        <button className={styles.btnPrimary} style={{ backgroundColor: "#59b2ab" }} onClick={() => window.open("https://play.google.com/store/apps/details?id=com.onlyrahulrai.bizflowsync")}>Install Now</button>
        <div className={styles.flexCenter}>
          <img
            src={scene}
            alt="download_png"
            className={`${styles.fullImg} mt-6`}
          />
        </div>
      </div>
    </div>
  )
}

export default Download;